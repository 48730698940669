import React from "react";
import styled from "styled-components";


import EbookIcon from "../../assets/img/services/ebook1.jpg";
import TestprepIcon from "../../assets/img/services/testPrep1.jpg";
import ContentIcon from "../../assets/img/services/handPicked1.jpg";
import OnlineIcon from "../../assets/img/services/onlineOffline1.jpg";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "ebook":
      getIcon = EbookIcon;
      break;
    case "testprep":
      getIcon = TestprepIcon;
      break;
    case "kkcontent":
      getIcon = ContentIcon;
      break;
    case "online":
      getIcon = OnlineIcon;
      break;
    default:
      getIcon = EbookIcon;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      {/* <IconStyle>{getIcon}</IconStyle> */}
      <img src={getIcon} alt="services" />
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font15" style={{ lineHeight: "20px" }}>{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  img{
    width: 125px;
    height:125px;
  }
  @media (max-width: 900px) {
     img{
       width:120px;
       height:120px;
       margin: 0 auto;
     }
  }
`;
// const IconStyle = styled.div`
//   @media (max-width: 860px) {
//     margin: 0 auto;
//   }
// `;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;