import React from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);

