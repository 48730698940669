import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

import Review1 from './../../assets/img/testimonial/omkar.jpg';
import Review2 from './../../assets/img/testimonial/akhastha.jpg';
import Review3 from './../../assets/img/testimonial/sheikhS.jpg';
import Review4 from './../../assets/img/testimonial/mahinKhan.jpg';
import Review5 from './../../assets/img/testimonial/rahulTiwari.jpg';
import Review6 from './../../assets/img/testimonial/zeeshan.jpg';


export default function TestimonialSlider() {


  const settings = {
    infinite: true,
    speed: 500,
    dots: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
      
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I am Omkar vishwakarma from rewa madhya pradesh.These books are very helpful for quick revision and also for quality numerical problems.If any one preparing for gate read these book for better understanding.These books are very helpful for a better understanding of som and tom and also mechanics are good.I took gate mechanical gounlimited premium and got test series also.In theory of machine numerical problems are too good.All books are good."
            author="Omkar Vishwakarma"
            picture={Review1}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="This is AKSHATHA B A and I am working as Assistant Professor in the civil department. I purchased an Air Pollution and Control book and it was a very informative one. All module wise solved questions are there which helped my students to do well in their semester examination. More and more books I want to purchase in future also."
            author="AKSHATHA B A"
            picture={Review2}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I just loved to read on the kopykitab app because it gives me detailed information about syllabus, contents and also it will give all the material which is very helpful for preparing for the GATE examination and it also includes the previous question papers with answers. Thank you kopykitab"
            author="Shaik Sahista"
            picture={Review3}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Hello My Name Is Maheen Khan. I Use kopykitab And I Am Truly Satisfied With kopyKitab Service.You Can Use kopykitab As Your E Book. There You Can Find All Exam Question Papers With Solutions. I Went To Market For Buying Some Solution Books And There I Found Very High Costs But Copykitab Has Very Low Cost As Compared  With Market.This Way Of Digital Learning Is Very Good And Very Beneficial For Students. Thank You kopykitab For Giving This Awesome Service."
            author="Maheen Khan"
            picture={Review4}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I bought a book from here whose price is less than the book.  So we think that online books save both money and time as compared to offline books."
            author="Rahul Tiwari"
            picture={Review5}
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Kopykitab is a very useful app for the Student's, Teachers and Learner...You can buy your eBook here at the lowest price from the market price..You can enjoy your learning anywhere u want..it also saves Ur time! Thank you!"
            author="Zeeshan Ahmed"
            picture={Review6}
          />
        </LogoWrapper>
       
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
