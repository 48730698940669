import React from "react";
import styled from "styled-components";

// import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// Components
// import FullButton from "../Buttons/FullButton";

import Dots from "../../assets/svg/Dots";

export default function Header() {

  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">10,000 Books for Rs 3/day</h1>
          <HeaderP className="font15 semiBold">
            {/* This library is a technology enabled platform where digital content in the form of eBooks, Test preps is created by Kopykitab.com. Entire content is curated by experts at Kopykitab.com by analyzing 80 lakh+ users' behavior and needs.  We work continuously to provide rich reading experience and anytime access to content from anywhere. We provide both online and offline access to required content from multiple devices. */}
            <ul style={{ listStyle: "disc", paddingLeft: "20px" }}>
              <li>One stop solution for all exam preparations.</li>
              <li>Entire content is curated by experts at Kopykitab.com by analyzing 80 lakh+ user behavior and needs to help users excel in exams.</li>
              <li>We work continuously to provide rich reading experience and anytime access to content from anywhere.</li>
              <li>We provide both online and offline access to required content from multiple devices.</li>
            </ul>
          </HeaderP>
          <BtnWrapper>
            {/* <FullButton title="Subscribe Now" /> */}
            <a href="https://pmny.in/7IxRiJzsY0kv">Subscribe Now Rs 3/day</a>
          </BtnWrapper>

        </div>
      </LeftSide>

      <RightSide>
        <ImageWrapper>
          <picture>
            <source srcSet="https://static.kopykitab.com/subdomain/kknotes/assets/img/webp/headerImg-1.webp" className="radius8" />
            <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/headerImg-1.jpg" alt="Header Banner 1" width={424} height={604} className="radius8" style={{ border: "1px solid rgba(11, 9, 59, .5)" }} />
          </picture>

          <QuoteWrapper className="flexCenter darkBg radius8">
            <div>
              <p className="font15 whiteColor textCenter">"INDIA'S 1st DIGITAL LIBRARY"</p>
              <p className="font14 orangeColor" style={{ marginTop: '10px' }}>Discover a library of 10,000+ eBooks for School, College, Competitive and Professional Exams.Subscribe to the library of more than 10,000 ebooks at 3 Rs/day
              </p>
            </div>
          </QuoteWrapper>

          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>

    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;

  a{
    border: 1px solid #7620ff;
    background-color: #7620ff;
    width: 100%;
    padding: 12px 36px;
    outline: none;
    color: #fff;
    border-radius: 12px;
  }
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;


const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
   h1{
     text-align: center;
   }
  }
  @media (max-width: 560px) {
    margin: 45px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 535px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 270px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
    @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
  img{
  @media (max-width: 425px) {
    width: 100%;
    height: 420px;
  }
  }
`;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 12px 30px;
  z-index: 99;
  border-radius:6px;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: 0;
    left: 0;
    height: 154px;
  }
`;
// const QuotesWrapper = styled.div`
//    position: absolute;
//    left: -20px;
//    top: -10px;
//  `;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


