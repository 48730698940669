import React from "react";
import LazyLoad from 'react-lazyload';
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Library for All</h1>
            <p className="font15" style={{ marginTop: "20px", marginBottom: "20px" }}>
              Kopykitab digital library offers a wide range of content for exams like NEET, IIT-JEE, CAT, UGC NET, State Exams, Railways, Banking, UGC-NET, CSIR-NET, Engineering, Class 9-12,  Etc.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 items">
              <Carousel showArrows={true} showStatus={false} showIndicators={false} autoPlay={true} infiniteLoop={true} fade={false} focusOnSelect={false} showThumbs={false} >
              
              <LazyLoad height={200} offset={100} once>
              <picture>
              <img srcSet="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/p1.jpg 1200w, https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/SchoolMob.png 400w" alt="Library For school" className="libraryImage" sizes="(min-width: 1280px) 1200px,
             (min-width: 420px) 400px, 100vw"></img>
              </picture>
              </LazyLoad>
              <LazyLoad height={200} offset={100} once>
              <picture>
              <img srcSet="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/p4.jpg 1200w, https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/EngineeringMob.png 400w" alt="Library For Engineering" className="libraryImage" sizes="(min-width: 1280px) 1200px,
             (min-width: 420px) 400px, 100vw"></img>
              </picture></LazyLoad>
              
              <LazyLoad height={200} offset={100} once>
              <picture>
              <img srcSet="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/p2.jpg 1200w, https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/CompetitiveMob.png 400w" alt="Library For Engineering" className="libraryImage" sizes="(min-width: 1280px) 1200px,
             (min-width: 420px) 400px, 100vw"></img>
              </picture>
              </LazyLoad>


                {/* <LazyLoad height={200} offset={100} once><img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/school.jpg" alt="Library For school" className="libraryImage" ></img></LazyLoad> */}
                {/* <LazyLoad height={200} offset={100} once><img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/engineering.jpg" alt="Library For Engineering" className="libraryImage" ></img></LazyLoad>
                <LazyLoad height={200} offset={100} once><img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/projects/competitive.jpg" alt="Library For Competitive Exams" className="libraryImage" ></img></LazyLoad> */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

// const Advertising = styled.div`
//   padding: 100px 0;
//   margin: 100px 0;
//   position: relative;
//   @media (max-width: 1160px) {
//     padding: 60px 0 40px 0;
//   }
//   @media (max-width: 860px) {
//     flex-direction: column;
//     padding: 0 0 30px 0;
//     margin: 80px 0 0px 0;
//   }
// `;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
// const AddLeft = styled.div`
//   position: relative;
//   width: 50%;
//   p {
//     max-width: 475px;
//   }
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//     text-align: center;
//     h2 {
//       line-height: 3rem;
//       margin: 15px 0;
//     }
//     p {
//       margin: 0 auto;
//     }
//   }
// `;
// const AddRight = styled.div`
//   width: 50%;
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//   }
// `;
// const AddLeftInner = styled.div`
//   width: 100%;
//   position: absolute;
//   top: -300px;
//   left: 0;
//   @media (max-width: 1190px) {
//     top: -250px;
//   }
//   @media (max-width: 920px) {
//     top: -200px;
//   }
//   @media (max-width: 860px) {
//     order: 1;
//     position: relative;
//     top: -60px;
//     left: 0;
//   }
// `;
// const ImgWrapper = styled.div`
//   width: 100%;
//   padding: 0 15%;
//   img {
//     width: 100%;
//     height: auto;
//   }
//   @media (max-width: 400px) {
//     padding: 0;
//   }
// `;
