import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import LazyLoad from 'react-lazyload';
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// import FullButton from "../Buttons/FullButton";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "30px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What you get</h1>
           
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="ebook"
                title="10,000+ eBooks"
                subtitle="Discover a library of 10,000+ eBooks for School, College, Competitive and Professional Exams created by experts at Kopykitab.com by analyzing 80 lakh+ users' behavior and needs."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="testprep"
                title="1000+ Test Preps"
                subtitle="Prepare with a library of 1000+ mock tests and topic wise tests for the preparation of various exams with unlimited attempts. Get a detailed scorecard and test analysis after completing the test."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="kkcontent"
                title="Handpicked Content"
                subtitle="Professional experts have picked up content so that you can focus on the preparation with best notes and content."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="online"
                title="Multiple Device"
                subtitle="Subscribe for one ID and access the whole library with 4 of your friends/siblings from multiple devices at just Rs 3/day."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">About Us</h4>
                <h2 className="font30 extraBold">KopyKitab is trusted by 80 Lakhs students from 50+ countries</h2>
                <p className="font14" style={{marginTop:"20px"}}>
                Kopykitab.com is "India's 1st digital Library" trusted by 80 lakh + users. We are dedicated to making education affordable & accessible. We want to bridge the gap between content providers & students using our technology platform. We have users from 200+ countries across the world. On an average a user spends 30 min of reading per day on our portal to achieve desired results.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "55px 0"}}>
                  <div style={{ width: "140px" }}>
                    <a href="https://pmny.in/7IxRiJzsY0kv" style={{border: "1px solid #7620ff",backgroundColor: "#7620ff", width: "100%", padding: "15px 14px", outline: "none", color: "#fff", borderRadius: "12px", fontSize: "14px"}}>Subscribe Now</a>
                  </div>
                  <div style={{ width: "140px" }}>
                  <Link to="contact" smooth={true} offset={-80} style={{border: "1px solid #707070",backgroundColor: "transparent",width: "100%", padding: "15px 15px", cursor:"pointer", color: "#707070", textAlign:"center", borderRadius: "12px", fontSize: "14px"}}> Contact Us</Link>
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                    <LazyLoad height={200} offset={100} once>
                      <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/add/School-students.jpg" width={285} height={406} alt="office" />
                    </LazyLoad>
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                    <LazyLoad height={200} offset={100} once>
                      <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/add/College-students.jpg" width={174} height={294} alt="office" />
                    </LazyLoad>
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                    <LazyLoad height={200} offset={100} once>
                      <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/add/Competitive-exam.jpg" width={116} height={129} alt="office" />
                    </LazyLoad>
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                    <LazyLoad height={200} offset={100} once>
                      <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/add/Professional-exam.jpg" width={174} height={198} alt="office" />
                    </LazyLoad>
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 34px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 92%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;