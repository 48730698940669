import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import LazyLoad from 'react-lazyload';
import Axios from 'axios';


export default function Contact() {
 


  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmit = data => {
       Axios
        .post(
            'https://www.kopykitab.com/index.php?route=account/applogin/contact_notes',
            data,
            { headers: { 'Content-Type': 'application/json' }}
         )
        .then(response => {console.log(response.data)})
        .catch(error => {console.log(error.data)});
    };
  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13" style={{ marginTop: "20px" }}>
              Have a question? Fill this form and we will reach out to you with answers to all your queries
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <label className="font13">Name:</label>
                <input style={{ marginBottom: "10px", color: "#000" }} type="text" id="fname" name="fname" className="font15" {...register("name", { required: "Name is required" })} />
                {errors.name && <p className='errorField' style={{ color: "red", fontSize: "12px" }}>{errors.name.message}</p>}
                <label className="font13">Email:</label>
                <input style={{ marginBottom: "10px", color: "#000" }} type="text" id="email" name="email" className="font15" {...register("email", { required: "Email is required", pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid Email Address" } })} />
                {errors.email && <p className='errorField' style={{ color: "red", fontSize: "12px" }}>{errors.email.message}</p>}
                <label className="font13">Phone No.:</label>
                <input style={{ marginBottom: "10px", color: "#000" }} type="text" id="phone" name="phone" className="font15" {...register("phone", { required: "phone is required", pattern: { value: /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/, message: "Invalid Phone Number" } })} />
                {errors.phone && <p className='errorField' style={{ color: "red", fontSize: "12px" }}>{errors.phone.message}</p>}
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font15" />

                <SumbitWrapper className="flex">
                  <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px", background: "#580cd2", height: "42px", padding: "6px", color: "#fff" }} />
                </SumbitWrapper>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <LazyLoad height={200} offset={100} once>
                    <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/Tablet.jpg" alt="office" className="radius6" style={{ width: "100%" }} />
                  </LazyLoad>
                </ContactImgBox>
                <ContactImgBox>
                  <LazyLoad height={200} offset={100} once>
                    <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/Mobile-phone.jpg" alt="office" className="radius6" style={{ width: "100%" }} />
                  </LazyLoad>
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <ContactImgBox>
                  <div style={{ marginTop: "100px" }}>
                    <LazyLoad height={200} offset={100} once>
                      <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/Laptop.jpg" alt="office" className="radius6" style={{ width: "100%" }} />
                    </LazyLoad>
                  </div>
                </ContactImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 30px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
  @media (max-width: 600px) {
    p{
      font-size: 14px;
    }
  }

`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
    color: #000;
    padding-top: 12px;
  }
  textarea {
    min-height: 100px;
  }
  label{
    position: relative;
    top: 10px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
  @media (max-width: 768px) {
     display:none;
  }
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
  }
`;
