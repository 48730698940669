import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets


export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer" to="home" smooth={true}>
            <img src="https://static.kopykitab.com/subdomain/kknotes/assets/img/kklogo.png" width={120} height={42} alt="logo"/>
           </Link>
           <div className="loginLink" style={{position:"relative", padding:"0", fontSize:"15px"}}>
           <a href="https://www.kopykitab.com/index.php?route=account/login" style={{cursor:"pointer", color:"#383838"}}>Login</a> | <a href="https://pmny.in/7IxRiJzsY0kv" style={{cursor:"pointer", color:"#fff", background:"#7620ff", padding:"4px 3px", borderRadius:"2px"}}>Subscribe Now</a>
           </div>
        </NavInner> 
      </Wrapper> 
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 60px;
`
// const BurderWrapper = styled.button`
//   outline: none;
//   border: 0px;
//   background-color: transparent;
//   height: 100%;
//   padding: 0 15px;
//   display: none;
//   @media (max-width: 760px) {
//     display: block;
//   }
// `;
// const UlWrapper = styled.ul`
//   display: flex;
//   @media (max-width: 760px) {
//     display: none;
//   }
// `;
// const UlWrapperRight = styled.ul`
//   @media (max-width: 760px) {
//     display: none;
//   }
// `;


