import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ text, author, picture }) {
  return (
    <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <p className="whiteColor font13" style={{ paddingBottom: "10px" }}>
        {text}
      </p>
      <p className="orangeColor font13" style={{alignSelf: 'flex-end'}}>
        <em>{author}</em> 
      </p>
      <img src={picture} alt="student testimonial" width={80} height={80} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 355px;
  padding: 20px 20px;
  margin-top: 30px;
  
  img{
    display: inline-block;
    align-self: flex-start;
    width: 80px;
    height: 80px;
    vertical-align: middle;
    position: relative;
    top: -20px;
    border-radius: 50%;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  top: 10px;
`;